import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const CarouselStyles = styled(Carousel)`
  width: 1200px; 
  max-width: 100%;
  margin: 1rem auto 0;

  .carousel-img-wrapper {
    width: 1200px;
    max-width: 100%;
    height: 550px;

    .gatsby-image-wrapper {
      height: 550px;
      max-width: 100%;
    }
  }

  @media (max-width: 800px) {
    .carousel-img-wrapper {
        height: 300px;

      .gatsby-image-wrapper {
        height: 300px;
      }
    }
  }

  @media (max-width: 500px) {
    .carousel-img-wrapper {
        height: 200px;

      .gatsby-image-wrapper {
        height: 200px;
      }
    }
  }
`

const SaleSectionStyles = styled.div`
  height: 400px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1em;
  display: flex;
  align-items: center;
  
  .gatsby-image-wrapper {
    width: 30%;
    align-self: center;
    margin-right: 1.5em;
  }

  .sale-content {
    border-left: 1px solid lightgray;
    padding-left: 1.5em;
    width: 70%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 2em 1em;

    .gatsby-image-wrapper {
      margin-right: 0;
      margin-bottom: 1em;
      width: 90%;
    }

    .sale-content {
      width: 90%;
      border-left: none;
      padding-top: 1em;
      padding-left: 0;
      border-top: 1px solid lightgray;
    }
  }
`

const MissionStyles = styled.div`
  padding: 2rem 0;

  h1 {
    font-family: "sf-intellivised",Arial,Helvetica,sans-serif;
    font-weight: 700;
    font-style: italic, oblique;
    font-size: 5rem;
    text-align: center;
  }

  h3 {
    font-family: "sf-intellivised",Arial,Helvetica,sans-serif;
    font-weight: 700;
    font-style: italic, oblique;
    font-size: 2rem;
    text-align: center;
    color: #D2042D;
  }
`

const InfoSectionStyles = styled.div`
  display: flex;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;
  min-height: 300px;

  .img-wrapper {
    width: 300px;
    padding: 2rem;
  }

  .content {
    padding: 1rem;
    width: 500px;
    border-left: 1px solid #000;

    .message {
      max-width: 600px;
      line-height: 1.25;
    }
  }

  &.reversed {
    flex-direction: row-reverse;

    .content {
      border-left: none;
      border-right: 1px solid #000;
    }
  }
`

const ClosingStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;

  p {
    max-width: 700px;
    line-height: 1.25;
    font-size: 1.2rem;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 2em 0;
  }

  @media (max-width: 800px) {
    margin: 0 1.5rem;
  }
`

export default function HomePage({ data, location }) {
  const siteTitle = "Home"
  const page = data.file.childMarkdownRemark.frontmatter;
  console.log("PAGE", page);
  const config = page.configuration;
  const saleImage = getImage(page.saleSection.image)
  const today = new Date();
  const saleStart = new Date(page.saleSection.startDate);
  const saleEnd = new Date(page.saleSection.endDate);
  const displaySale = today >= saleStart && today <= saleEnd;
  console.log("Display Sale", displaySale);

  return (
    <Layout location={location} title={config?.title || siteTitle}>
      <SEO
        title={config?.title || siteTitle}
        keywords={config?.keywords || []}
      />
      <CarouselStyles 
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={false}
        showStatus={false}>
        {page.carousel.map(cImage => (
          <div key={cImage.label} className="carousel-img-wrapper">
            <GatsbyImage 
              image={getImage(cImage.image)}
              alt={cImage.label}
            />
          </div>
        ))}
      </CarouselStyles>
      {displaySale && (
        <SaleSectionStyles>
          <GatsbyImage 
            image={saleImage}
            alt={page.saleSection.title}
          />
          <div className="sale-content">
            <h3>{page.saleSection.title}</h3>
            <p>{page.saleSection.description}</p>
          </div>
        </SaleSectionStyles>
      )}
      <MissionStyles>
        <h1>{page.mission}</h1>
      </MissionStyles>
      {page.infoSections.map((section, index) => (
        <InfoSectionStyles key={section.title} className={index % 2 !== 0 ? "reversed" : ""}>
          <div className="img-wrapper">
            <GatsbyImage 
              image={getImage(section.image)}
              alt={section.title}
            />
          </div>
          <div className="content">
            {section.title && <h2>{section.title}</h2>}
            <p className="message">{section.message}</p>
          </div>
        </InfoSectionStyles>
      ))}
      <MissionStyles>
        <h3>Best jog cart wheels in the world. Period!</h3>
      </MissionStyles>
      <ClosingStyles>
        <p>{page.closingMessage}</p>
      </ClosingStyles>
    </Layout>
  );
}


export const query = graphql`
  query HomePageQuery {
    file(sourceInstanceName: { eq: "pages" }, name: { eq: "index" }) {
      name
      childMarkdownRemark {
        id
        frontmatter {
          templateKey
          configuration {
            title
            keywords
          }
          carousel {
            label
            image {
            	name
              childImageSharp {
                gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
              }
            }
          }
          saleSection {
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
              }
            }
            title
            description
            startDate
            endDate
          }
          mission
          infoSections {
            title
            message
            image {
            	name
              childImageSharp {
                gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
              }
            }
          }
          closingMessage
        }
      }
    }
  }
`